import type {InputHTMLAttributes} from 'react';
import {cva} from 'class-variance-authority';

import {twMerge} from '@/stylesheets/twMerge';
import Checkbox from '@/components/base/elements/Checkbox/Checkbox';
import Typography from '@/components/base/elements/Typography/Typography';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label: string;
  state?: 'success' | 'warning' | 'error' | 'disabled';
  mode?: 'light' | 'dark';
}

const labelStyles = cva('text-base', {
  variants: {
    mode: {
      light: 'text-black',
      dark: 'text-white',
    },
  },
});

export default function DisclaimerCheckbox({
  className,
  label,
  state,
  mode = 'light',
  ...inputProps
}: CheckboxProps) {
  return (
    <div
      data-component-name="marketing_communication_disclaimer_text"
      className={twMerge('flex items-start', className)}
      data-mode={mode}
    >
      <Checkbox
        mode={mode}
        label={label}
        aria-describedby={label}
        {...inputProps}
        key="marketing_communication_consent"
        id="marketing_communication_consent"
        name="marketing_communication_consent"
      />
      <div className="relative -top-1 left-2">
        <Typography
          as="label"
          className={labelStyles({mode})}
          htmlFor="marketing_communication_consent"
        >
          {label}
        </Typography>
      </div>
    </div>
  );
}
